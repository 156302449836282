import React from 'react';
import {useRouter} from 'next/router';
import {Grid, Typography, Box, useTheme} from '@mui/material';
import CategoryItem from '@/common/components/CategoryItem';
import {appRoutes, resolveAudioUrl, resolveImageUrl} from '@/common/config';
import ListingBox from './ListingBox';
import {
  AudioUploadStatus,
  ImageUploadStatus,
  MenuItem,
} from '@/common/api/types';
import {useUserContext} from '../contexts/UserContext';
import {RiInformationLine} from 'react-icons/ri';
import Tooltip from '@mui/material/Tooltip';

interface CategorySectionProps {
  onClickRate: (listingId: number) => void | Promise<void>;
  onClickClaim: (listingId: number) => void | Promise<void>;
  menuItems: MenuItem[];
}

export default function CategorySection({
  onClickRate,
  onClickClaim,
  menuItems,
}: CategorySectionProps) {
  const {push} = useRouter();
  const user = useUserContext();
  const theme = useTheme();

  return (
    <>
      <Box display="flex" alignItems="center" marginTop={2} marginBottom={1}>
        <Typography variant="h6">Top Listings by Category</Typography>
        <div>
          <Tooltip
            title={
              <h1 style={{color: 'primary', fontSize: 16, lineHeight: 1.5}}>
                These are the top listings in each of our featured categories as
                rated by our users.
              </h1>
            }
          >
            <Box marginLeft={1}>
              <RiInformationLine color="primary" size={24} />
            </Box>
          </Tooltip>
        </div>
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-evenly"
        paddingTop={3}
        paddingBottom={2}
        sx={{
          width: '100%',
        }}
      >
        <Grid container justifyContent="space-evenly">
          {menuItems.map(category => (
            <Box
              key={category.name}
              sx={{
                width: '100%',
              }}
            >
              <CategoryItem
                name={category.name}
                imageUrl={
                  category.imageId ? resolveImageUrl(category.imageId) : ''
                }
                onClick={() => push(appRoutes.home.category(category.name))}
              />

              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                {category.topListings?.map(listing => (
                  <Box
                    key={listing.id}
                    sx={{
                      width: '33.3333%',
                      [theme.breakpoints.down('lg')]: {
                        width: '50%',
                      },
                      [theme.breakpoints.down('md')]: {
                        width: '100%',
                      },
                    }}
                  >
                    <ListingBox
                      id={listing.id}
                      name={listing.fullName}
                      facebookProfile={listing.facebookProfile}
                      instagramProfile={listing.instagramProfile}
                      tiktokProfile={listing.tiktokProfile}
                      podcastURL={listing.podcastURL}
                      twitterHandle={listing.twitterHandle}
                      twitterFollowers={listing.twitterFollowers}
                      threadsProfile={listing.threadsProfile}
                      goodreadsProfile={listing.goodreadsProfile}
                      mailingListProfile={listing.mailingListProfile}
                      reviewCount={listing.reviewCount}
                      rating={listing.reviewRating}
                      thumbnailUrl={
                        listing.imageStatus === ImageUploadStatus.APPROVED &&
                        listing.imageId
                          ? resolveImageUrl(listing.imageId)
                          : undefined
                      }
                      productThumbnailUrl={
                        listing.productImageStatus ===
                          ImageUploadStatus.APPROVED && listing.productImageId
                          ? resolveImageUrl(listing.productImageId)
                          : undefined
                      }
                      productUrl={
                        listing.productImageStatus ===
                          ImageUploadStatus.APPROVED && listing.productUrl
                          ? listing.productUrl
                          : undefined
                      }
                      productAudioUrl={
                        listing.productAudioStatus ===
                          AudioUploadStatus.APPROVED && listing.productAudioId
                          ? resolveAudioUrl(listing.productAudioId)
                          : undefined
                      }
                      badges={listing.badges}
                      hasCurrentUserReviewed={listing.hasCurrentUserReviewed}
                      showClaimButton={!user.listingId}
                      onClickRate={onClickRate}
                      onClickClaim={onClickClaim}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
        </Grid>
      </Box>
    </>
  );
}
