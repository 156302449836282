import React, {useState} from 'react';
import {Grid, Typography, Box, Paper, useTheme} from '@mui/material';
import HeaderBar from '@/common/layouts/HeaderBar';
import DefaultLayout from '@/common/layouts/DefaultLayout';
import HashtagBox from '@/common/components/HashtagBox';
import Division from '@/common/components/Division';
import {QueryClient, useQuery} from 'react-query';
import {queries} from '@/common/api/operations';
import {
  appRoutes,
  flags,
  resolveAudioUrl,
  resolveImageUrl,
} from '@/common/config';
import {useRouter} from 'next/router';
import CategorySection from '@/common/components/CategorySection';
import {getServerSidePropsPrefetch} from '@/common/utils/ssr';
import {ssrMenuItems, useMenuItems} from '@/common/hooks/useMenuItems';
import {ApiClient, useApiContext} from '@/common/contexts/ApiContext';
import {ssrSearchListingsAndHashtagsAutocomplete} from '@/admin/features/autocomplete/useSearchListingsAndHashtagsAutocomplete';
import ListingBox from '@/common/components/ListingBox';
import ListingRatingDialog from './search/ListingRatingDialog';
import ListingClaimDialog from './search/ListingClaimDialog';
import {
  AudioUploadStatus,
  ImageUploadStatus,
  ListingsSearchResponse,
  PopularListingsPaginationResponse,
  HashtagsSearchResponse,
  PopularHashtagsResponse,
} from '@/common/api/types';
import {useUserContext} from '@/common/contexts/UserContext';
import {BadgeBreakdown, ssrBadges} from '@/common/components/BadgeBreakdown';
import HeroBanner from '@/common/components/HeroBanner';
import SimpleCategorySection from '@/common/components/SimpleCategorySection';
import Footer from '@/common/layouts/Footer';
import {RiInformationLine} from 'react-icons/ri';
import Tooltip from '@mui/material/Tooltip';
import LoadingButton from '@/common/components/LoadingButton';

import CookieConsent from 'react-cookie-consent';

async function ssrPopular(queryClient: QueryClient, apiClient: ApiClient) {
  await queryClient.prefetchQuery([queries.search.popularHashtags], () =>
    apiClient.search.popularHashtags({
      pageSize: 25,
      skipCount: 0,
    }),
  );
}

const PAGE_SIZE = 25;

function usePopular() {
  const pageSize = PAGE_SIZE;
  const [newListings, setNewListings] = useState<ListingsSearchResponse[]>([]);
  const [newHashtags, setNewHashtags] = useState<HashtagsSearchResponse[]>([]);
  const {apiClient} = useApiContext();
  const [newHashtagSkipCount, setNewHashtagSkipCount] = React.useState(0);
  const popularHashtagsResult = useQuery(
    [queries.search.popularHashtags, newHashtagSkipCount],
    () =>
      apiClient.search.popularHashtags({
        pageSize,
        skipCount: newHashtagSkipCount,
      }),
    {
      onSuccess: (data: PopularHashtagsResponse) => {
        // onSuccess is called after updating the cache and after a network request
        // therefore we need to support updates and insertions of listings
        // using a map by inserting the current listings and then the new listings achieves thhat
        setNewHashtags(currentHashtags => {
          const map = new Map<number, HashtagsSearchResponse>();

          for (const next of currentHashtags) {
            map.set(next.id, next);
          }

          for (const next of data.newHashtags) {
            map.set(next.id, next);
          }

          return [...map.values()];
        });
      },
    },
  );
  const [skipCount, setSkipCount] = React.useState(0);
  const popularListingResults = useQuery(
    [queries.search.popularListingsPagination, skipCount],
    () =>
      apiClient.search.popularListingsPagination({
        pageSize,
        skipCount,
      }),
    {
      onSuccess: (data: PopularListingsPaginationResponse) => {
        // onSuccess is called after updating the cache and after a network request
        // therefore we need to support updates and insertions of listings
        // using a map by inserting the current listings and then the new listings achieves thhat
        setNewListings(currentListings => {
          const map = new Map<number, ListingsSearchResponse>();

          for (const next of currentListings) {
            map.set(next.id, next);
          }

          for (const next of data.newListings) {
            map.set(next.id, next);
          }

          return [...map.values()];
        });
      },
    },
  );
  // useBottomScrollListener(
  //   // () => {},
  //   () => {
  //     setSkipCount(old => old + pageSize);
  //   },
  //   {
  //     debounce: 1000,
  //   },
  // );

  const listings = popularListingResults?.data?.newListings ?? [];

  const updateNewListingCachedItem = (listing: ListingsSearchResponse) => {
    setNewListings(currentListings =>
      currentListings.map(next => (next.id === listing.id ? listing : next)),
    );
  };

  const hashtags = popularHashtagsResult.data?.newHashtags ?? [];

  return {
    featuredHashtags: popularHashtagsResult.data?.featuredHashtags ?? [],
    newHashtags,
    newListings,
    setSkipCount,
    setNewHashtagSkipCount,
    isListingLoadingMore: popularListingResults.isLoading,
    isNewHashtagLoadingMore: popularHashtagsResult.isLoading,
    updateNewListingCachedItem,
    noMoreListingToLoad:
      listings?.length === 0 || listings.length <= PAGE_SIZE - 1,
    noMoreNewHashtagToLoad:
      hashtags?.length === 0 || hashtags?.length <= PAGE_SIZE - 1,
  };
}

export default function RootPage() {
  const {push} = useRouter();
  const {
    featuredHashtags,
    newHashtags,
    newListings,
    setSkipCount,
    setNewHashtagSkipCount,
    isListingLoadingMore,
    isNewHashtagLoadingMore,
    updateNewListingCachedItem,
    noMoreListingToLoad,
    noMoreNewHashtagToLoad,
  } = usePopular();
  const user = useUserContext();
  const theme = useTheme();
  const {menuItems, allListings} = useMenuItems();
  const [selectedListingId, setSelectedListingId] = useState<number | null>(
    null,
  );
  const [claimListingId, setClaimListingId] = useState<number | null>(null);
  const handleSelectListing = (listingId: number) => {
    setSelectedListingId(listingId);
  };
  const closeClaimListingDialog = () => {
    setClaimListingId(null);
  };
  const handleClaimListing = (listingId: number) => {
    setClaimListingId(listingId);
  };

  return (
    <>
      <ListingRatingDialog
        isOpen={Boolean(selectedListingId)}
        listingName={
          newListings.find((n: {id: number}) => n.id === selectedListingId)
            ?.fullName ??
          allListings.find((n: {id: number}) => n.id === selectedListingId)
            ?.fullName
        }
        listingId={selectedListingId}
        setListingId={setSelectedListingId}
        onRatedListing={updateNewListingCachedItem}
        hasCurrentUserReviewed={
          newListings?.find((n: {id: number}) => n.id === selectedListingId)
            ?.hasCurrentUserReviewed ??
          allListings?.find((n: {id: number}) => n.id === selectedListingId)
            ?.hasCurrentUserReviewed
        }
      />
      {flags.ENABLE_CLAIM_LISTING && (
        <ListingClaimDialog
          isOpen={Boolean(claimListingId)}
          twitterHandle={
            newListings.find((n: {id: number}) => n.id === claimListingId)
              ?.twitterHandle ??
            allListings.find((n: {id: number}) => n.id === claimListingId)
              ?.twitterHandle ??
            ''
          }
          onClose={closeClaimListingDialog}
          onClaimCompleted={closeClaimListingDialog}
        />
      )}

      <HeaderBar />
      <DefaultLayout>
        <Grid container direction="column">
          <HeroBanner />
          <CookieConsent>
            This website uses cookies to enhance the user experience.
          </CookieConsent>

          <Box marginBottom={2}>
            <SimpleCategorySection menuItems={menuItems} />
          </Box>

          <Box display="flex" alignItems="center" marginBottom={2}>
            <Typography variant="h6">Featured Hashtags</Typography>
            <div>
              <Tooltip
                title={
                  <h1 style={{color: 'primary', fontSize: 16, lineHeight: 1.5}}>
                    These hashtags have been featured by our content curators.
                  </h1>
                }
              >
                <Box marginLeft={1}>
                  <RiInformationLine color="primary" size={24} />
                </Box>
              </Tooltip>
            </div>
          </Box>

          <Paper>
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="space-evenly"
              padding={1}
            >
              {featuredHashtags.map(hashtag => (
                <Box key={hashtag.id} margin={1}>
                  <HashtagBox
                    name={hashtag.name}
                    totalListings={hashtag.totalListings}
                    onClick={() =>
                      push(appRoutes.home.search(`${hashtag.name}`))
                    }
                  />
                </Box>
              ))}
            </Box>
          </Paper>

          <Box marginTop={2}>
            <BadgeBreakdown />
          </Box>

          <CategorySection
            menuItems={menuItems}
            onClickRate={handleSelectListing}
            onClickClaim={handleClaimListing}
          />

          <Box
            display="flex"
            alignItems="center"
            marginTop={2}
            marginBottom={2}
          >
            <Typography variant="h6">New Hashtags</Typography>
            <div>
              <Tooltip
                title={
                  <h1 style={{color: 'primary', fontSize: 16, lineHeight: 1.5}}>
                    These hashtags have recently been added. We hope you find
                    something you like.
                  </h1>
                }
              >
                <Box marginLeft={1}>
                  <RiInformationLine color="primary" size={24} />
                </Box>
              </Tooltip>
            </div>
          </Box>

          <Paper>
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="space-evenly"
              padding={1}
            >
              {newHashtags.map(hashtag => (
                <Box key={hashtag.id} margin={1}>
                  <HashtagBox
                    name={hashtag.name}
                    totalListings={hashtag.totalListings}
                    onClick={() =>
                      push(appRoutes.home.search(`${hashtag.name}`))
                    }
                  />
                </Box>
              ))}
            </Box>

            {!noMoreNewHashtagToLoad && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginTop={1}
                paddingY={4}
              >
                <LoadingButton
                  loading={isNewHashtagLoadingMore}
                  onClick={() => setNewHashtagSkipCount(old => old + PAGE_SIZE)}
                >
                  Show more
                </LoadingButton>
              </Box>
            )}
          </Paper>

          <Box
            display="flex"
            alignItems="center"
            marginTop={2}
            marginBottom={2}
          >
            <Typography variant="h6">New Listings</Typography>
            <div>
              <Tooltip
                title={
                  <h1 style={{color: 'primary', fontSize: 16, lineHeight: 1.5}}>
                    These listings have recently been added. We hope you find
                    something you like.
                  </h1>
                }
              >
                <Box marginLeft={1}>
                  <RiInformationLine color="primary" size={24} />
                </Box>
              </Tooltip>
            </div>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            {newListings.map(listing => (
              <Box
                key={listing.id}
                sx={{
                  width: '33.3333%',
                  [theme.breakpoints.down('lg')]: {
                    width: '50%',
                  },
                  [theme.breakpoints.down('md')]: {
                    width: '100%',
                  },
                }}
              >
                <ListingBox
                  id={listing.id}
                  name={listing.fullName}
                  facebookProfile={listing.facebookProfile}
                  instagramProfile={listing.instagramProfile}
                  tiktokProfile={listing.tiktokProfile}
                  podcastURL={listing.podcastURL}
                  twitterHandle={listing.twitterHandle}
                  twitterFollowers={listing.twitterFollowers}
                  threadsProfile={listing.threadsProfile}
                  goodreadsProfile={listing.goodreadsProfile}
                  mailingListProfile={listing.mailingListProfile}
                  reviewCount={listing.reviewCount}
                  rating={listing.reviewRating}
                  thumbnailUrl={
                    listing.imageStatus === ImageUploadStatus.APPROVED &&
                    listing.imageId
                      ? resolveImageUrl(listing.imageId)
                      : undefined
                  }
                  productThumbnailUrl={
                    listing.productImageStatus === ImageUploadStatus.APPROVED &&
                    listing.productImageId
                      ? resolveImageUrl(listing.productImageId)
                      : undefined
                  }
                  productUrl={
                    listing.productImageStatus === ImageUploadStatus.APPROVED &&
                    listing.productUrl
                      ? listing.productUrl
                      : undefined
                  }
                  productAudioUrl={
                    listing.productAudioStatus === AudioUploadStatus.APPROVED &&
                    listing.productAudioId
                      ? resolveAudioUrl(listing.productAudioId)
                      : undefined
                  }
                  badges={listing.badges}
                  hasCurrentUserReviewed={listing.hasCurrentUserReviewed}
                  showClaimButton={!user.listingId}
                  onClickRate={handleSelectListing}
                  onClickClaim={handleClaimListing}
                />
              </Box>
            ))}
          </Box>

          {!noMoreListingToLoad && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              marginTop={1}
              paddingY={4}
            >
              <LoadingButton
                loading={isListingLoadingMore}
                onClick={() => setSkipCount(old => old + PAGE_SIZE)}
              >
                Show more
              </LoadingButton>
            </Box>
          )}

          <Division />
        </Grid>
      </DefaultLayout>
      <Footer />
    </>
  );
}

export const getServerSideProps = getServerSidePropsPrefetch([
  ssrMenuItems,
  ssrSearchListingsAndHashtagsAutocomplete,
  ssrPopular,
  ssrBadges,
]);
