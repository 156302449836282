import React from 'react';
import {useRouter} from 'next/router';
import {Grid, Typography, Box, Paper} from '@mui/material';
import CategoryItem from '@/common/components/CategoryItem';
import {appRoutes, resolveImageUrl} from '@/common/config';
import {MenuItem} from '../api/types';
import {RiInformationLine} from 'react-icons/ri';
import Tooltip from '@mui/material/Tooltip';
interface SimpleCategorySectionProps {
  menuItems: MenuItem[];
}

export default function SimpleCategorySection({
  menuItems,
}: SimpleCategorySectionProps) {
  const {push} = useRouter();

  return (
    <>
      <Box display="flex" alignItems="center" marginBottom={2}>
        <Typography variant="h6">Categories</Typography>
        <div>
          <Tooltip
            title={
              <h1 style={{color: 'primary', fontSize: 16, lineHeight: 1.5}}>
                These are the primary categories featured by our content
                curators.
              </h1>
            }
          >
            <Box marginLeft={1}>
              <RiInformationLine color="primary" size={24} />
            </Box>
          </Tooltip>
        </div>
      </Box>

      <Paper>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-evenly"
          paddingTop={3}
          paddingBottom={2}
        >
          <Grid container justifyContent="space-evenly">
            {menuItems.map(category => (
              <CategoryItem
                key={category.name}
                name={category.name}
                imageUrl={
                  category.imageId ? resolveImageUrl(category.imageId) : ''
                }
                onClick={() => push(appRoutes.home.category(category.name))}
              />
            ))}
          </Grid>
        </Box>
      </Paper>
    </>
  );
}
