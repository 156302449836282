import React from 'react';
import { Typography, Box } from '@mui/material';
import { useGlobalContext } from '@/common/contexts/GlobalContext';

export default function HeroBanner() {
  const { siteName, siteLogoURL, siteTagline, siteTaglineShort } = useGlobalContext()

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      marginTop={2}
      marginBottom={2}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        sx={{
          background: '#24262D',
          borderRadius: '20px',
          width: '300px',
          height: '300px',
          position: 'relative',
        }}
        boxShadow={4}
      >
        <Box sx={{
          width: '140px',
          height: '140px',
          borderRadius: '50%',
          position: 'absolute',
          top: '-10px',
          right: '80px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <img style={{
            width: '80px',
          }} src={siteLogoURL} />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          sx={{
            marginTop: '75px',
          }}
        >
          <Typography sx={{
            fontSize: '36px',
            fontWeight: 'bold',
            textAlign: 'center',
            backgroundColor: '#24262D',
            zIndex: 1,
          }}>{siteName}</Typography>
          <Typography sx={{
            marginBottom: '8px',
            fontWeight: 'bold',
          }}>
            {siteTaglineShort}
          </Typography>
          <Typography color="primary" sx={{
            textAlign: 'center',
            fontSize: '14px',
            fontWeight: 'bold',
            width: '75%',
          }}>
            {siteTagline}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
