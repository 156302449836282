import { Button, Box } from '@mui/material';

interface CategoryItemProps {
  name: string;
  imageUrl: string;
  onClick: () => void;
}

export default function CategoryItem({
  name,
  imageUrl,
  onClick,
}: CategoryItemProps) {

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      onClick={onClick}
      mx="auto"
      marginTop={2}
      marginLeft={2.5}
      marginRight={2.5}
      boxShadow={4}
      padding={1}
    >
      <img src={imageUrl} style={{
        borderRadius: '20px',
        width: '120px',
        height: '120px',
        cursor: 'pointer',
        boxShadow: '3px 3px 3px #111',
      }} alt={name} title={name} />
      <Button variant="text" color="primary" sx={{
        textTransform: 'none',
      }}>
        #{name}
      </Button>
    </Box>
  );
}
