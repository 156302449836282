import { Button, Box } from '@mui/material';
import { formatShortCount } from '@/common/utils/common';
import React from 'react';

interface HashtagBoxProps {
  name: string;
  totalListings?: number;
  onClick?: () => void;
}

export default function HashtagBox({
  name,
  totalListings,
  onClick,
}: HashtagBoxProps) {
  return (
    <Box display="flex" alignItems="center">
      <Button
        variant="outlined"
        color="primary"
        size="small"
        sx={{
          textTransform: 'none',
        }}
        onClick={onClick}
      >
        #{name}
      </Button>
      {totalListings !== undefined && totalListings > 0 && (
        <Box sx={{
          color: 'primary.main',
          borderColor: 'primary.main',
          borderStyle: 'solid',
          borderWidth: '1px',
          boxShadow: 1,
          fontSize: 11,
          fontWeight: 'bold',
          padding: '3px',
          borderRadius: '4px',
          marginLeft: '8px',
          position: 'relative',
          '&:after': {
            content: '""',
            display: 'block',
            width: 0,
            height: 0,
            position: 'absolute',
            borderTopWidth: '6px',
            borderTopStyle: 'solid',
            borderTopColor: 'transparent',
            borderBottomWidth: '6px',
            borderBottomStyle: 'solid',
            borderBottomColor: 'transparent',
            borderRightWidth: '6px',
            borderRightStyle: 'solid',
            borderRightColor: 'primary.main',
            left: '-6px',
            top: '4px',
          },
        }}>
          {formatShortCount(Math.round(totalListings), '')}
        </Box>
      )}
    </Box>
  );
}
